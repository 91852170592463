import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppLoaderService } from 'src/app/_shared_/core/loader/services/app-loader.service';
import { SharedAuthenticationService } from 'src/app/_shared_/app/authentication/services/authentication.service';
import { IUser } from 'src/app/_user/interfaces/user.interface';
import { SharedDataService } from 'src/app/_shared_/app-shared/services/shared-data.service';
import { SharedUsersService } from 'src/app/_shared_/app/users/services/shared-users.service';
// import { ICommunity } from 'src/app/_shared_/app/community/interfaces/community.interface';
// import { Company } from 'src/app/_shared_/app/companies/models/company.model';
import { UserType } from 'src/app/_shared_/app/users/models/users_types.enum';
import { UserOwns } from 'src/app/_shared_/app/users/models/user-owns.model';
// import { Community } from 'src/app/_shared_/app/community/models/community.model';
import { OwnerRoles } from 'src/app/_shared_/app/users/models/owner-roles.enum';
import { Subject } from 'rxjs';

// declare var FB: any;

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit, OnDestroy {
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      event.stopPropagation();
      this.onSubmit();
    }
  }
  public email!: string;
  public password!: string;
  public bad_credentials_error: boolean = false;
  public account_not_activated_error: boolean = false;
  public whatUserOwns: any;
  public userType!: UserType;
  public current_user_uuid!: string;
  public isPopupAfterLoginOpened: boolean = false;
  // public user_owned_communities: ICommunity[] = [];
  // public user_owned_companies: Company[] = [];
  // public user_subscribed_communities: ICommunity[] = [];
  // public user_subscribed_companies: Company[] = [];
  public unsubscribe$: Subject<void> = new Subject();

  constructor(
    private sharedAuthenticationService: SharedAuthenticationService,
    private router: Router,
    private location: Location,
    private appLoaderService: AppLoaderService,
    private sharedDataService: SharedDataService,
    private sharedUsersService: SharedUsersService
  ) {
    this.sharedAuthenticationService.logout();
  }

  ngOnInit(): void {
    this.email = 'rmgomes@live.fr';
    this.password = 'xxx';

  }

  public async onSubmit() {
    this.appLoaderService.startLoading();

    this.sharedAuthenticationService
      .signin(this.email, this.password)
      .then(async (user: IUser) => {

        if (!user) return;

        this.current_user_uuid = user.uuid;

        if (user.first_account_configuration) {
          this.whatUserOwns = await this.sharedUsersService.getWhatUserOwns(user.uuid);

          this.setUserType();
          this.setWhatUserOwnsOnSharedData(this.whatUserOwns);
          // this.setUserOwnedCommunities();
          // this.setUserOwnedCompanies();
          // this.setUserSubscribedCommunities();
          // this.setUserSubscribedCompanies();

          this.appLoaderService.stopLoading();
          if (this.userType === 'COMPANY' || this.userType === 'COMPANY_OWNER') {
            // this.goToOwnedCompany(this.sharedDataService.sharedData$.value.whatCurrentUserOwns.companies[0].uuid);
          } else {
            this.isPopupAfterLoginOpened = true;
          }
        } else {
          this.router.navigate(['/config/first-account-configuration/step-0']);
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);


        if (
          err.error.error === 'bad_credentials' ||
          err.error.error === 'user_not_found'
        ) {
          this.bad_credentials_error = true;
        } else {
          this.bad_credentials_error = false;
        }
        if (err.error.error === 'account_not_activated') {
          this.account_not_activated_error = true;
        } else {
          this.account_not_activated_error = false;
        }
        this.appLoaderService.stopLoading();
      });
  }

  private setUserType(): void {
    if (this.whatUserOwns.communities?.length) this.userType = 'ADMIN';
    if (this.whatUserOwns.companies?.length) this.userType = 'COMPANY';
    if (!this.userType) this.userType = 'USER';
  }

  private setWhatUserOwnsOnSharedData(userOwns: UserOwns): void {
    this.sharedDataService.sharedData$.value.whatCurrentUserOwns = userOwns;
  }

  // private setUserOwnedCommunities(): void {
  //   if (!this.whatUserOwns.communities?.length) return;
  //   this.whatUserOwns.communities.find((community: ICommunity) => {
  //     community.owners.forEach((owner) => {
  //       if (owner.user_uuid === this.current_user_uuid && owner.roles.includes(OwnerRoles.ADMIN) || owner.roles.includes(OwnerRoles.MODERATOR)) {
  //         this.user_owned_communities.push(community);
  //       }
  //     })
  //   })
  // }

  // private setUserOwnedCompanies(): void {
  //   if (!this.whatUserOwns.companies?.length) return;
  //   this.whatUserOwns.companies.find((company: Company) => {
  //     company.owners.forEach((owner) => {
  //       if (owner.user_uuid === this.current_user_uuid && owner.roles.includes(OwnerRoles.ADMIN) || owner.roles.includes(OwnerRoles.MODERATOR)) {
  //         this.user_owned_companies.push(company);
  //       }
  //     })
  //   })
  // }

  // public goToOwnedCommunity(community_uuid: string): void {
  //   this.isPopupAfterLoginOpened = false;
  //   this.appLoaderService.startLoading();
  //   this.router.navigate(['/', 'app', 'admin', this.current_user_uuid, 'community', community_uuid]);
  //   return;
  // }

  // public goToCommunitySubscribedCommunity(community_uuid: string): void {


  //   this.isPopupAfterLoginOpened = false;
  //   this.appLoaderService.startLoading();
  //   this.router.navigate(['/', 'app', 'admin', this.current_user_uuid, 'community', community_uuid, 'landing-follower']);
  //   return;
  // }

  // public goToOwnedCompany(company_uuid: string): void {
  //   let community_uuid = this.sharedAuthenticationService.getCurrentSession().last_visited_community;
  //   community_uuid = community_uuid ? community_uuid : environment.defaulCommunityUUID;

  //   this.appLoaderService.startLoading();
  //   this.isPopupAfterLoginOpened = false;
  //   this.router.navigate(['/', 'app', 'company', company_uuid, 'community', community_uuid]);
  // }

  // public goToUserCommunity(community_uuid: string): void {
  //   const user_uuid = this.sharedAuthenticationService.getCurrentSession().uuid;
  //   this.appLoaderService.startLoading();
  //   this.isPopupAfterLoginOpened = false;
  //   this.router.navigate(['/', 'app', 'user', user_uuid, 'community', community_uuid]);
  // }
  // public goToSubscribedCommunity(community_uuid: string): void {
  //   this.appLoaderService.startLoading();
  //   this.isPopupAfterLoginOpened = false;
  //   this.router.navigate(['/', 'app', 'user', this.current_user_uuid, 'communities', community_uuid]);
  // }
  // public goToSubscribedCompany(company_uuid: string): void {
  //   this.appLoaderService.startLoading();
  //   this.isPopupAfterLoginOpened = false;
  //   this.router.navigate(['/', 'app', 'company', company_uuid]);
  // }
  // public goToCreateNewCompany(): void {
  //   this.appLoaderService.startLoading();
  //   this.isPopupAfterLoginOpened = false;
  //   this.router.navigate(['config/first-account-configuration/join-as-company'], { queryParams: { uuid: this.current_user_uuid } })
  // }

  // public goToCreateNewCommunity(): void {
  //   this.appLoaderService.startLoading();
  //   this.isPopupAfterLoginOpened = false;
  //   this.router.navigate(['config/first-account-configuration/join-as-admin'], { queryParams: { uuid: this.current_user_uuid } })
  // }

  public resendActivationEmail(): void {
    this.sharedAuthenticationService.resendActivationMail(this.email).then(() => {
      this.router.navigate(['/', 'account-activation'], { queryParams: { email_sent: true } });
    })
  }


  // private setUserSubscribedCommunities(): void {

  //   if (!this.whatUserOwns.subscribed_communities?.length) return;

  //   this.user_subscribed_communities = this.whatUserOwns.subscribed_communities;
  // }

  // private setUserSubscribedCompanies(): void {
  //   if (!this.whatUserOwns.companies?.length) return;
  //   this.whatUserOwns.companies.find((company: Company) => {
  //     company.actors.forEach((actor) => {
  //       if (actor.actor_uuid === this.current_user_uuid && actor.type === 'COMPANY') {
  //         this.user_owned_companies.push(company);
  //       }
  //     })
  //   })
  // }


  public recheckInputs(): void {
    if (this.bad_credentials_error) this.bad_credentials_error = false;
  }

  public onForgotPassword(): void {
    this.router.navigate(['/', 'auth', 'password-forgotten']);
  }

  public onPasswordChange($event: string) {
    this.recheckInputs();
    this.password = $event;
  }

  public onEmailChange($event: string) {
    this.recheckInputs();
    this.email = $event;
  }

  public goBack(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}