
<main class="profile-setup">

  <section class="content">
    <div class="avatar-placeholder" aria-hidden="true"></div>
    <h1 class="title">Let's setup your profile</h1>
    <p class="description">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    </p>
    <p class="highlight">
      Creating a nice profile will help you to stand out among others
    </p>
  </section>

  <footer class="action-bar">
    <button (click)="goBack()" class="back-button">
      Back
    </button>
    <button (click)="startSetup()" class="start-button">Let's start</button>
  </footer>

  <div class="bottom-indicator" aria-hidden="true"></div>
</main>