import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    constructor() { }

    public getCookie(name: string): string | undefined {
        let result = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)")
        return result ? result.pop() : ""
    }

    public setCookie(name: string, path:string, value: string): void{        
        document.cookie = `${name}=${value};path=${path};`;
    }

    public deleteCookie(name: string, path:string){
        document.cookie = `${name}=;path=${path};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
}