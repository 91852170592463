import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SharedAuthenticationService } from 'src/app/_shared_/app/authentication/services/authentication.service';
import { SharedCompaniesService } from 'src/app/_shared_/app/companies/services/companies-service';
import { environment } from 'src/environments/environment';
import {
  IFirstAccountConfigurationCreate,
  IFirstAccountConfigurationJoinAsCorporation,
  IFirstAccountConfigurationJoinAsPerson,
} from '../interfaces/first-account-configuration.interface';
import { MFirstAccountConfiguration } from '../models/first-account-configuration.model';
import { FirstAccountConfigurationDataService } from './first-account-configurationData.service';

@Injectable()
export class FirstAccountConfigurationStateService {
  public firstAccountConfiguration: MFirstAccountConfiguration =
    new MFirstAccountConfiguration();

  constructor(
    private router: Router,
    private firstAccountConfigurationDataService: FirstAccountConfigurationDataService,
    private sharedAuthenticationService: SharedAuthenticationService,
  ) { }

  public setCreateOrJoin(createOrJoin: 'CREATE' | 'JOIN'): void {
    this.firstAccountConfiguration.create_or_join = createOrJoin;

  }

  public setCreationConfig(
    creationObj: IFirstAccountConfigurationCreate
  ): void {
    this.setCreateOrJoin('CREATE');
    this.firstAccountConfiguration.create = creationObj;

    this.postAndGoToApp();
  }

  public setCorporationConfig(
    corporationObj: IFirstAccountConfigurationJoinAsCorporation
  ): void {
    this.setCreateOrJoin('JOIN');
    this.firstAccountConfiguration.join_as_company = corporationObj;

    this.postAndGoToApp();
  }

  public setPersonConfig(
    personObj: IFirstAccountConfigurationJoinAsPerson
  ): void {
    this.setCreateOrJoin('JOIN');
    this.firstAccountConfiguration.join_as_person = personObj;

    this.postAndGoToApp();
  }

  public async postAndGoToApp(): Promise<void> {
    let community_uuid = this.sharedAuthenticationService.getCurrentSession().last_visited_community;
    community_uuid = community_uuid ? community_uuid : environment.defaulCommunityUUID;
    let user_uuid = this.sharedAuthenticationService.getCurrentSession().uuid;

    this.firstAccountConfigurationDataService
      .postFirstAccountConfiguration(this.firstAccountConfiguration)
      .then((res) => {
        
        if(res && res.community_uuid) community_uuid = res.community_uuid;

        if (this.firstAccountConfiguration.create?.userType === 'ADMIN') {
          this.router.navigate(['/', 'app', 'admin', user_uuid, 'community', community_uuid]);
        }
        if (this.firstAccountConfiguration.join_as_company?.userType === 'CORPORATION') {
          this.router.navigate(['/', 'app', 'company', res.uuid, 'community', community_uuid]);
        }
        if (this.firstAccountConfiguration.join_as_person?.userType === 'PERSON') {
          this.router.navigate(['/', 'app', 'user', user_uuid, 'community', community_uuid]);
        }
      });
  }
}
