import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppLoaderParams } from '../models/app-loader-params.model';

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {
  public isLoading$ = new BehaviorSubject<boolean | null>(null);
  public params$ = new BehaviorSubject<AppLoaderParams | null>(null);

  constructor() {}

  public isLoading(): boolean | null {
    return this.isLoading$.value;
  }

  public startLoading(params?: AppLoaderParams): void {
    if(params){
      this.params$.next(params);
    }

    return this.isLoading$.next(true);
  }

  public stopLoading(): void {
    setTimeout(() => {
      this.params$.next({closeButtonText: '', description: '', showCloseButton: false, title: ''});
      return this.isLoading$.next(false);
    }, 300);
  }
}
