export class ScreenSize {
  width!: number;
  height!: number;
  type!: 'LARGE' | 'SMALL' | 'TABLET' | 'MOBILE';

  constructor(rect: DOMRectReadOnly) {
    this.width = rect.width;
    this.height = rect.height;
    
    if (rect.width >= 1250) this.type = 'LARGE';
    if (rect.width < 1250 && rect.width > 1000) this.type = 'SMALL';
    if (rect.width < 1000 && rect.width > 800) this.type = 'TABLET';
    if (rect.width < 800) this.type = 'MOBILE';

    return this;
  }
}
