import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { AppLoaderService } from 'src/app/_shared_/core/loader/services/app-loader.service';
import { SharedAuthenticationService } from 'src/app/_shared_/app/authentication/services/authentication.service';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  // public email: string = '';
  // public password: string = '';
  // public password_confirm: string = '';
  public cgu_cgv: boolean = false;
  
  public invalid_form: boolean = false;
  public email_error: boolean = false;
  public passwords_error: boolean = false;

  public accountSuccessfullyCreated: boolean = false;


  // GENERATED
  email: string = '';
  password: string = '';
  confirmPassword: string = '';
  agreeTerms: boolean = false;

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      event.stopPropagation();
      this.signup();
    }
  }

  constructor(
    private location: Location,
    private appLoaderService: AppLoaderService,
    private sharedAuthenticationService: SharedAuthenticationService
  ) {}

  ngOnInit(): void {
    this.appLoaderService.stopLoading();
  }

  onSubmit() {
    this.signup();
  }

  public goBack(): void {
    this.location.back();
  }

  public signup(): void {
    this.recheckCredentials();
    
    
    if (this.email === '' || this.password === '' || this.confirmPassword === '' || !this.cgu_cgv) {
      this.invalid_form = true;
      return;
    }

    this.appLoaderService.startLoading();

    this.sharedAuthenticationService
      .signup({
        email: this.email,
        password: this.password,
        password_confirm: this.confirmPassword,
      })
      .then((response) => {
        
        this.appLoaderService.stopLoading();
        if (response && response.status && response.status === 'ok') {
          this.accountSuccessfullyCreated = true;
          this.email_error = false;
          this.passwords_error = false;
        }
      })
      .catch((err) => {
        
        if (err && err.error && err.error.error === 'user_exists') {
          this.email_error = true;
        }
        if (err && err.error && err.error.error === 'passwords_doesnt_match') {
          this.passwords_error = true;
        }
        this.appLoaderService.stopLoading();
      });
  }

  public recheckCredentials(): void {
    if (this.email_error || this.passwords_error || this.invalid_form) {
      this.email_error = false;
      this.passwords_error = false;
      this.invalid_form = false;
    }
  }

  public onChangeCheckboxCGU_CGV($event: Event): void{
    this.cgu_cgv = !this.cgu_cgv;    
  }

  public onEmailChange($event: string) : void{
    this.recheckCredentials();
    this.email = $event;
  }
  public onPasswordChange($event: string) : void{
    this.recheckCredentials();
    this.password = $event;
  }
  public onConfirmPasswordChange($event: string) : void{
    this.recheckCredentials();
    this.confirmPassword = $event;
  }
}
