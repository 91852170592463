import { NgModule } from "@angular/core";
import { SharedScreenService } from "./services/shared-screen.service";


@NgModule({
  declarations: [
  
  ],
  imports: [
  
  ],
  exports: [
  
  ],
  providers: [
    SharedScreenService
  ]
})
export class SharedScreenModule {}
