import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutsPageService } from '../../services/layouts-page.service';

declare var FB: any;

@Component({
  selector: 'layouts-page',
  templateUrl: './layouts-page.component.html',
  styleUrls: ['./layouts-page.component.scss'],
})
export class LayoutsPageComponent implements AfterViewInit {
  public menu!: any[];
  
  public selected_layout: 'EMPTY' | 'SIMPLE' | null = null;

  public menu_toggle_button!: { type: string; name: string };

  constructor(
    private router: Router,
    private layoutsPageService: LayoutsPageService
  ) {

    this.layoutsPageService.selectedLayout$.subscribe((selected_layout) => {
      if(selected_layout){
        this.selected_layout = selected_layout;        
      }
    })

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        

        if (ev.url.includes('auth') || !ev.url.includes('app')) {
          this.layoutsPageService.selectedLayout$.next('EMPTY');
        } else {
          this.layoutsPageService.selectedLayout$.next('SIMPLE');
        }
      }
    });
  }

  ngAfterViewInit(): void {
    // (window as any).fbAsyncInit = () => {
      

    //   FB.init({
    //     appId: '612824403182414',
    //     cookie: true,
    //     xfbml: true,
    //     version: 'v12.0',
    //   });

    //   FB.AppEvents.logPageView();

    //   FB.getLoginStatus((response: any) => {
        
    //     if (response && response.status && response.status === 'connected') {
    //       this.router.navigate(['app', 'community']);
    //     }
    //   }, this);
    // };
  }
}
