import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { firstValueFrom } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class SharedPicturesService {

   
    constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) { }

    public postProfilePicture(user_uuid: string, formData: any): Promise<any> {
        return firstValueFrom(this.httpClient
            .post(`${environment.apiUrl}users/${user_uuid}/profile/picture`, formData))
    }

   

    public getProfilePicture(user_uuid: string): SafeUrl {
        return `${environment.apiUrl}static/profile_picture/${user_uuid}`;
        

    }



  


}