import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IUser } from 'src/app/_user/interfaces/user.interface';
import { SharedDataService } from 'src/app/_shared_/app-shared/services/shared-data.service';
import { environment } from 'src/environments/environment';
import { SharedAuthenticationService } from '../../authentication/services/authentication.service';
import { UserOwns } from '../models/user-owns.model';
import { UserIBAN } from '../models/user-iban.model';

@Injectable()
export class SharedUsersService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  public searchUsersAutocomplete(searchText: string): Promise<any[] | undefined> {
    return firstValueFrom(this.httpClient
      .post<any[] | undefined>(`${environment.apiUrl}users/search`, { searchText }))
  }

  public checkPassword(user_uuid: string, password: string): Promise<any> {
    return firstValueFrom(this.httpClient
      .post(environment.apiUrl + 'users/' + user_uuid + '/check_password', {
        password
      }));
  }
  
  public changePassword(user_uuid: string, password: string): Promise<any> {
    return firstValueFrom(this.httpClient
      .post(environment.apiUrl + 'users/' + user_uuid + '/change_password', {
        password
      }));
  }

  public searchCommunityUsersAutocomplete(searchText: string, community_uuid: string): Promise<any[] | undefined> {
    return firstValueFrom(this.httpClient
      .post<any[] | undefined>(`${environment.apiUrl}users/search/communities/${community_uuid}`, { searchText }));
  }

  public getUser(user_uuid: string | undefined): Promise<IUser | undefined> {
    return firstValueFrom(this.httpClient
      .get<IUser | undefined>(`${environment.apiUrl}users/${user_uuid}`))
  }
  
  public getSelfUserContactsList(user_uuid: string | undefined): Promise<IUser[]> {
    return firstValueFrom(this.httpClient
      .get<IUser[]>(`${environment.apiUrl}users/${user_uuid}/contacts-list`))
  }

  public getWhatUserOwns(user_uuid: string): Promise<UserOwns> {
    return firstValueFrom(this.httpClient.get<UserOwns>(`${environment.apiUrl}users/${user_uuid}/getWhatUserOwns`));
  }

  public updateUserIban(user_uuid: string, iban_obj: UserIBAN): Promise<any> {
    return firstValueFrom(this.httpClient
      .post<any[] | undefined>(`${environment.apiUrl}users/${user_uuid}/profile/iban`, iban_obj))
  }

  public deleteUserAccount(user_uuid: string): Promise<void> {
    return firstValueFrom(this.httpClient.delete<void>(`${environment.apiUrl}users/${user_uuid}/delete_account`));
  }

}
