import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-0',
  templateUrl: './step-0.component.html',
  styleUrls: ['./step-0.component.scss'],
})
export class Step0Component implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() { }
  startSetup(): void {
    console.log('Starting profile setup');
    this.router.navigate(['config', 'first-account-configuration', 'step-1'])

  }

  goBack(): void {
    console.log('Going back');
    this.router.navigate(['/', 'auth', 'signin']);
  }
}
