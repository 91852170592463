import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Step0Component } from './pages/step-0/step-0.component';
import { Step1Component } from './pages/step-1/step-1.component';
import { Step2Component } from './pages/step-2/step-2.component';
import { Step3Component } from './pages/step-3/step-3.component';
import { Step4Component } from './pages/step-4/step-4.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'join-as',
    pathMatch: 'prefix'
  },
  {
    path: 'step-0',
    pathMatch: 'full',
    component: Step0Component,
  },
  {
    path: 'step-1',
    pathMatch: 'full',
    component: Step1Component,
  },
  {
    path: 'step-2',
    pathMatch: 'full',
    component: Step2Component,
  },
  {
    path: 'step-3',
    pathMatch: 'prefix',
    component: Step3Component,
  },
  {
    path: 'step-4',
    pathMatch: 'prefix',
    component: Step4Component,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FirstAccountConfigurationRoutingModule {}
