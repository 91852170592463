import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppLoaderService } from 'src/app/_shared_/core/loader/services/app-loader.service';
import { SharedAuthenticationService } from 'src/app/_shared_/app/authentication/services/authentication.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements AfterViewInit {
  private resetToken!: string;

  public password!: string;
  public password_confirm!: string;

  public passwords_not_matching_error: boolean = false;

  public passwordWasReseted: boolean = false;
  public somethingWentWrong: boolean = false;
  public tokenNotValidError: boolean = false;

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      event.stopPropagation();
      this.resetPassword();
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private appLoaderService: AppLoaderService,
    private sharedAuthenticationService: SharedAuthenticationService
  ) {
    this.appLoaderService.stopLoading();
    this.resetToken = this.activatedRoute.snapshot.params['reset_token'];
  }

  ngAfterViewInit(): void {}

  public recheckCredentials(): void {
    this.passwords_not_matching_error = false;
  }

  public resetPassword(): void {
    if (this.password !== this.password_confirm) {
      this.passwords_not_matching_error = true;
      return;
    }

    

    this.sharedAuthenticationService
      .passwordReset({
        reset_token: this.resetToken,
        password: this.password,
        password_confirm: this.password_confirm,
      })
      .then((res) => {
        if (res && res.message && res.message === 'token_not_valid') {
          this.tokenNotValidError = true;
        }
        if (res && res.message && res.message === 'ok') {
          this.passwordWasReseted = true;
        }
        
      })
      .catch((err) => {
        
      });
  }

  public onPasswordChange($event: string): void {
    this.recheckCredentials();
    this.password = $event;
  }

  public onConfirmPasswordChange($event: string): void {
    this.recheckCredentials();
    this.password_confirm = $event;
  }
}
