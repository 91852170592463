import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SharedAuthenticationService } from '../../app/authentication/services/authentication.service';
import { CookieService } from '../../core/cookies/services/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedGuard {
  constructor(private http: HttpClient, private cookieService: CookieService, private router: Router, private sharedAuthenticationService: SharedAuthenticationService) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {

      firstValueFrom(this.http.post(environment.apiUrl + 'auth/reauth', { token: this.cookieService.getCookie(environment.cookieName) })).then(res => {

        this.cookieService.setCookie(
          environment.cookieName,
          environment.cookiePath,
          (res as any).bearer as string
        );

        resolve(true);
      }).catch((err) => {
        this.router.navigate(['auth/signin']);
        reject(false);
      })
    })
  }

}
