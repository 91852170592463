import { Routes } from '@angular/router';
import { IsLoggedGuard } from './_shared_/app-shared/guards/is-logged.guard';
import { AppCanActivateGuard } from './app-can-activate.guard';

export const routes: Routes = [
  /**
   * AUTHENTICATION MODULE
   */
  {
    path: 'auth',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  /**
   * LANDING PAGE OFFLINE MODULE
   */
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./landing/landing-routes').then((m) => m.LANDING_ROUTES),
  },
   /**
   * FIRST ACCOUNT CONFIG MODULE
   */
   {
    path: 'config/first-account-configuration',
    canActivate: [IsLoggedGuard, AppCanActivateGuard],
    pathMatch: 'prefix',
    loadChildren: () =>
      import(
        './first-account-configuration/first-account-configuration.module'
      ).then((m) => m.FirstAccountConfigurationModule),
  },



  /**
   * APP ROUTES FOR LOGGED USERS
   */
  {
    path: 'app',
    pathMatch: 'prefix',
    canActivate: [IsLoggedGuard, AppCanActivateGuard],
    children: [
      // {
      //   path: 'users',
      //   loadChildren: () =>
      //     import('./_user/users.module').then((m) => m.UsersModule)
      // }
    ]
  }
];
