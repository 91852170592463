import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { AppLoaderService } from 'src/app/_shared_/core/loader/services/app-loader.service';
import { SharedAuthenticationService } from 'src/app/_shared_/app/authentication/services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.scss'],
})
export class PasswordForgottenComponent implements OnInit {
  public email!: string;
  public emailSent: boolean = false;
  public apiError: boolean = false;
  public env = environment;

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      event.stopPropagation();
      this.requestPasswordReset();
    }
  }
  constructor(
    private location: Location,
    private authenticationService: SharedAuthenticationService,
    private appLoaderService: AppLoaderService
  ) {}

  ngOnInit(): void {}

  public requestPasswordReset(): void {
    this.appLoaderService.startLoading();

    this.authenticationService
      .requestPasswordReset(this.email)
      .then((response) => {
        this.emailSent = true;
        this.appLoaderService.stopLoading();
      })
      .catch((err) => {
        this.apiError = true;
        this.appLoaderService.stopLoading();
        
      });
  }

  public onEmailChange($event:string):void{
    this.email = $event;
  }

  public goBack(): void {
    this.location.back();
  }
}
