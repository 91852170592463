import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IFirstAccountConfiguration } from '../interfaces/first-account-configuration.interface';

@Injectable()
export class FirstAccountConfigurationDataService {
  constructor(private httpClient: HttpClient) {}

  public postFirstAccountConfiguration(firstAccountConfig: IFirstAccountConfiguration): Promise<any> {
    return firstValueFrom(this.httpClient
      .post<any>(
        environment.apiUrl + 'first-account-configuration',
        firstAccountConfig
      ))
  }
}
