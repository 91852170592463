import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withPreloading, PreloadAllModules } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { importProvidersFrom } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SharedUsersModule } from './app/_shared_/app/users/user-shared.module';
import { AuthenticationModule } from './app/authentication/authentication.module';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from './environments/environment';
import { CookieService } from './app/_shared_/core/cookies/services/cookie.service';
import { AppCanActivateGuard } from './app/app-can-activate.guard';
import { FirstAccountConfigurationModule } from './app/first-account-configuration/first-account-configuration.module';

const config: SocketIoConfig = {
  url: environment.ws_url, options: {
    withCredentials: false,
    query: {
      'token': new CookieService().getCookie(environment.cookieName) as string || 'GUEST'
    }
  }
};

bootstrapApplication(AppComponent, {
  providers: [
    AppCanActivateGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom([
      SocketIoModule.forRoot(config),
      AuthenticationModule,
      SharedUsersModule,
      FirstAccountConfigurationModule
    ])
  ],
});
