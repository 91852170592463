<main class="login-container">
   
    <div class="avatar-placeholder"></div>
    
    <h1 class="login-title">Log in to your account</h1>
    
    <form (ngSubmit)="onSubmit()" class="login-form">
      <div class="form-group">
        <label for="email" class="form-label">Email</label>
        <input type="email" id="email" name="email" required class="form-input" [(ngModel)]="email"  />
      </div>
      
      <div class="form-group">
        <label for="password" class="form-label">Password</label>
        <input type="password" id="password" name="password" required class="form-input" [(ngModel)]="password" />
      </div>
      
      <button type="submit" class="login-button">Log in</button>
    </form>
    
    <p class="forgot-password">
      Forgot password? <span (click)="onForgotPassword()" class="reset-link" tabindex="0">Let's reset it</span>
    </p>
    
    <footer class="bottom-indicator"></footer>
  </main>