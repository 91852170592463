import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppLoaderService } from 'src/app/_shared_/core/loader/services/app-loader.service';
import { SharedAuthenticationService } from 'src/app/_shared_/app/authentication/services/authentication.service';

@Component({
  selector: 'account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss'],
})
export class AccountActivationComponent implements AfterViewInit {
  public wasAccountActivated: boolean | null = null;
  public invalidToken: boolean = false;
  public wasNewLinkSent: boolean = false;
  public token_expired: boolean = false;
  private activationToken!: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private appLoaderService: AppLoaderService,
    private sharedAuthenticationService: SharedAuthenticationService
  ) {
    this.appLoaderService.startLoading();
    this.activationToken = this.activatedRoute.snapshot.params['activation_token'];
  }

  ngAfterViewInit(): void {
    this.sharedAuthenticationService
      .activateAccount(this.activationToken)
      .then((res) => {
        this.appLoaderService.stopLoading();
        if (
          res &&
          res.name &&
          res.name === 'err' &&
          res.message === 'invalid_token'
        ) {
          this.invalidToken = true;
          return;
        }
        if (res && res.message && res.message === 'token_expired') {
          this.token_expired = true;
          return;
        }
        this.wasAccountActivated = true;
      })
      .catch((err) => {
        this.appLoaderService.stopLoading();
        this.wasAccountActivated = false;
        console.error(err);
      });
  }

  public sendNewActivationLink(): void {
    this.wasAccountActivated = null;
    this.invalidToken = false;

    this.appLoaderService.startLoading();

    this.sharedAuthenticationService
      .sendNewActivationMail(this.activationToken)
      .then((res) => {
        if (
          res &&
          res.name &&
          res.name === 'err' &&
          res.message &&
          res.message === 'invalid_token'
        ) {
          this.invalidToken = true;
          this.appLoaderService.stopLoading();
          return;
        }
        this.appLoaderService.stopLoading();
        this.wasNewLinkSent = true;
      })
      .catch((err) => {
        this.wasAccountActivated = false;
        

        if (
          err &&
          err.error.name &&
          err.error.name === 'account_already_activated'
        ) {
          this.wasAccountActivated = true;
        }
        this.appLoaderService.stopLoading();
      });
  }
}
