// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mockApplicationData: false,
  apiUrl: 'http://vps-7350214b.vps.ovh.net:8001/',
  ws_url: 'http://vps-7350214b.vps.ovh.net:3012',
  cookieName: 'x-be.eva-token',
  cookieSameSite : 'None',
  appname: 'BE.EVA',
  cookiePath: '/',
  defaultUserUUID: '8df122a4-43b2-472c-a4b9-238e1fa0f3e3',
  defaulCommunityUUID: 'b883c68c-25fb-417c-8a26-96a8e4525428',
  defaultUserPictureUUID: 'e244d205-634b-4fd0-8065-048a5dd57d43',
  deploy: {
    server: 'ubuntu',
    server_ip: '92.222.172.218',
    server_link:  'vps-7350214b.vps.ovh.net',
    username: 'ubuntu',
    app_folder_name: 'BE.EVA',
    ssh_port: 22,
    ssh_private_key_folder: 'C:/Users/rmgom/.ssh/key_to_beeva_ovh_vps',
    ssh_private_key_pwd: 'Ho$Ct7sgC%A9B%08',
    git_ssh_clone: 'git@github.com:TARIIS-Lda/BE.EVA.git'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
