<div class="profile-setup-interests">
  <header class="header">
    <div class="status-bar">
      <time>9:27</time>
    </div>
    <h1 class="step-indicator">4/4: Interests</h1>
  </header>
  <main>
    <h2 class="main-question">What industry you're passionate about?</h2>
    <ul class="interest-list">
      <li class="interest-item">
        <button class="interest-button">
          <span class="interest-indicator"></span>
          Software engineering
        </button>
      </li>
      <li class="interest-item">
        <button class="interest-button">
          <span class="interest-indicator"></span>
          Quality automation
        </button>
      </li>
      <li class="interest-item">
        <button class="interest-button">
          <span class="interest-indicator"></span>
          Marketing
        </button>
      </li>
      <li class="interest-item">
        <button class="interest-button">
          <span class="interest-indicator"></span>
          Sales management
        </button>
      </li>
      <li class="interest-item">
        <button class="interest-button">
          <span class="interest-indicator"></span>
          Human resources
        </button>
      </li>
      <li class="interest-item">
        <button class="interest-button">
          <span class="interest-indicator"></span>
          Design
        </button>
      </li>
      <li class="interest-item">
        <button class="interest-button">
          <span class="interest-indicator"></span>
          Project management
        </button>
      </li>
      <li class="interest-item">
        <button class="interest-button">
          <span class="interest-indicator"></span>
          Business analytics
        </button>
      </li>
    </ul>
  </main>
  <footer class="footer">
    <div class="navigation-buttons">
      <button class="back-button" (click)="goBack()">
        Back
      </button>
      <button class="finish-button" (click)="startSetup()">Finish</button>
    </div>
    <button class="skip-button" (click)="startSetup()">Skip for now, will fill in later</button>
    <div class="bottom-indicator" aria-hidden="true"></div>
  </footer>
</div>