<section class="profile-setup">
  <header class="header">

    <h1 class="step-title">1/4: Personal info</h1>
    <h2 class="section-title">Tell us a bit about yourself</h2>
    <figure class="profile-picture-container">
      <figcaption class="profile-picture-label">Profile picture</figcaption>
    </figure>
  </header>

  <form class="personal-info-form">
    <div class="form-group">
      <label for="fullName" class="form-label">
        Full name<span class="required-asterisk">*</span>
      </label>
      <input type="text" id="fullName" class="form-input" placeholder="e.g. José Silva" required />
    </div>

    <div class="form-group">
      <label for="shortBio" class="form-label">
        Short bio<span class="required-asterisk">*</span>
      </label>
      <textarea id="shortBio" class="form-input form-textarea" placeholder="e.g. I'm passionate about developing great and important products and would like to find a company in which I can be helpful. Also I enjoy playing basketball in my free time" required></textarea>
    </div>
  </form>

  <footer class="footer">
    <button class="btn btn-back" aria-label="Go back" (click)="goBack()">
      Back
    </button>
    <button class="btn btn-next" (click)="startSetup()">Next</button>
  </footer>

  <div class="bottom-indicator" aria-hidden="true"></div>
</section>