import { SafeUrl } from "@angular/platform-browser";
import { IUser } from "src/app/_user/interfaces/user.interface";
// import { IActorCommunity } from "../../app/community/interfaces/community-actor.interface";
// import { Community } from "../../app/community/models/community.model";
// import { IActorCompany } from "../../app/companies/interfaces/company-actor.interface";
// import { Company } from "../../app/companies/models/company.model";
import { IOwner } from "../../app/users/interfaces/owner.interface";
import { IActorUser } from "../../app/users/interfaces/user-actor.interface";
import { UserOwns } from "../../app/users/models/user-owns.model";
import { UserType } from "../../app/users/models/users_types.enum";
import { RouteParams } from "./route-params.model";
import { AppConfig } from "./appconfig.model";

export class SharedData {
    // public currentCommunity!: Community;
    public currentCommunityUserAdmin!: IOwner;
    // public currentCommunityActor!: IActorUser;
    // public currentCompany!: Company;
    public currentCompanyUserAdmin!: IOwner;
    public currentCompanyActor!: IActorUser;
    public currentRouteParams!: RouteParams;
    public currentUser!: IUser;
    public currentUserPicture!: SafeUrl;
    public whatCurrentUserOwns!: UserOwns;
    public routeUrl!: string;
    public userType!: UserType;
    public appconfig!: AppConfig;
    /**
     * Used to kwnow everywhere if the data was emitted by the sharedDataService
     */
    public isEmit!: boolean;
    /**
     * Used to set and know from where the data was emitted;
     */
    public wasEmittedFrom!: string;
}
