import { RouteParams } from './../models/route-params.model';
import { Injectable } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom, forkJoin, map, Observable, tap } from "rxjs";
import { environment } from "src/environments/environment";
// import { ICommunity } from "../../app/community/interfaces/community.interface";
// import { SharedCommunityService } from "../../app/community/services/shared-community.service";
// import { Company } from "../../app/companies/models/company.model";
// import { SharedCompaniesService } from "../../app/companies/services/companies-service";
import { SharedData } from "../models/shared-data.model";
import { SharedPicturesService } from "./shared-pictures.service";
import { UserOwns } from '../../app/users/models/user-owns.model';
import { SharedUsersService } from '../../app/users/services/shared-users.service';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../models/appconfig.model';
import { CookieService } from '../../core/cookies/services/cookie.service';


@Injectable({
    providedIn: 'root',
})
export class SharedDataService {

    public sharedData$ = new BehaviorSubject(new SharedData());
    private sharedDataEmissions: SharedData[] = [];
    public changes$ = new BehaviorSubject(new SharedData());

    constructor(
        // private sharedCommunityServie: SharedCommunityService,
        // private sharedCompanyServie: SharedCompaniesService,
        private sharedPictureServices: SharedPicturesService,
        private sharedUsersService: SharedUsersService,
        private sharedCookiesService: CookieService,
        private httpClient: HttpClient
    ) {
      
    }

    public watchOnline$(): Observable<SharedData> {
        return this.sharedData$.pipe(distinctUntilChanged(), filter((v) => v.isEmit && v.wasEmittedFrom !== 'loadAndSetAppConfig'))
    }

    public watchOffline$(): Observable<SharedData> {
        return this.sharedData$.pipe(distinctUntilChanged(), filter((v) => v.isEmit))
    }



    public async init(routeParams: RouteParams, routeUrl: string): Promise<void> {


        this.sharedData$.value.routeUrl = routeUrl;

        this.setUserType();

        forkJoin({
            a: this.updateCurrentUser(),
            c: this.setRouteParams(routeParams),
            // d: this.loadAndsetCommunity(routeParams),
            // e: this.loadAndsetCompany(routeParams),
            f: this.loadAndsetWhatUserOwns(),
            g: this.loadAndSetAppConfig()
        }).subscribe((v) => {
            
            // console.error('LZLEFLZEFLZEFL');
            // console.error(v);
            // console.error(JSON.parse(JSON.stringify(this.sharedData$.value)));
            this.reEmitI(this.sharedData$.value);
        })

    }

    public updateCurrentUser(): Promise<void> {
        this.sharedData$.value.currentUser = this.decodeJwt(this.sharedCookiesService.getCookie(environment.cookieName))?.user;
        this.setCurrentUserProfilePicture()

        return Promise.resolve();
    }

    private decodeJwt(token?: string) {
        if (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );

            return JSON.parse(jsonPayload);
        }
        return null;
    }

    private setUserType(): void {
        const routeUrl = this.sharedData$.value.routeUrl;
        if (routeUrl.indexOf('/app/admin/') !== -1) this.sharedData$.value.userType = 'ADMIN';
        if (routeUrl.indexOf('/app/user/') !== -1) this.sharedData$.value.userType = 'USER';
        if (routeUrl.indexOf('/app/company/') !== -1) this.sharedData$.value.userType = 'COMPANY';
    }

    // private loadAndsetCommunity(routeParams: RouteParams): Promise<ICommunity> {
    //     const community_uuid = routeParams.community_uuid ? routeParams.community_uuid : environment.defaulCommunityUUID;
    //     return this.sharedCommunityServie.getCommunity(community_uuid).then((community) => {
    //         this.sharedData$.value.currentCommunity = community;
    //         this.setCurrentCommunityUserAdmin()
    //         this.setCurrentCommunityUserActor();
    //         return community;
    //     })
    // }

    public async loadAndSetAppConfig(): Promise<AppConfig> {
        return firstValueFrom(this.httpClient.get<AppConfig>(environment.apiUrl + 'backoffice/appconfig')).then((appconfig: AppConfig) => {
            this.sharedData$.value.appconfig = appconfig;
            this.reEmitI(this.sharedData$.value, 'loadAndSetAppConfig');
            return appconfig;
        })
    }

    // private loadAndsetCompany(routeParams: RouteParams): Promise<Company | null> {
    //     if (!routeParams.company_uuid) return Promise.resolve(null);
    //     return this.sharedCompanyServie.getCompany(routeParams.company_uuid).then((company) => {
    //         this.sharedData$.value.currentCompany = company;
    //         this.setCurrentCompanyUserAdmin();
    //         return company;
    //     })
    // }

    private setCurrentUserProfilePicture(): SafeUrl {
        if (!this.sharedData$.value.currentUser) return Promise.resolve("");

        const currentUserUUID = this.sharedData$.value.currentUser.uuid;

        this.sharedData$.value.currentUserPicture = this.sharedPictureServices.getProfilePicture(currentUserUUID);
        return this.sharedData$.value.currentUserPicture;
    }

    private setRouteParams(routeParams: RouteParams): Promise<RouteParams> {
        this.sharedData$.value.currentRouteParams = routeParams;
        return Promise.resolve(routeParams);
    }

    private loadAndsetWhatUserOwns(): Promise<UserOwns | null> {
        if (!this.sharedData$.value.currentUser) return Promise.resolve(null);

        const currentUserUUID = this.sharedData$.value.currentUser.uuid;

        return this.sharedUsersService.getWhatUserOwns(currentUserUUID).then((userOwns) => {
            this.sharedData$.value.whatCurrentUserOwns = userOwns;

            return userOwns;
        })
    }


    // private setCurrentCommunityUserAdmin(): void {
    //     const isCurrentUserCommunityAdministrator = this.sharedData$.value.currentCommunity.owners.find((communityOwner) => communityOwner.user_uuid === this.sharedData$.value.currentUser?.uuid);
    //     if (isCurrentUserCommunityAdministrator) {
    //         this.sharedData$.value.currentCommunityUserAdmin = isCurrentUserCommunityAdministrator;
    //     }
    // }

    // private setCurrentCompanyUserAdmin(): void {
    //     if (this.sharedData$.value.userType === 'COMPANY' && this.sharedData$.value.currentCompany) {
    //         const currentUserCompanyAdministrator = this.sharedData$.value.currentCompany.owners.find((companyOwner) => companyOwner.user_uuid === this.sharedData$.value.currentUser.uuid);
    //         if (currentUserCompanyAdministrator) {
    //             this.sharedData$.value.currentCompanyUserAdmin = currentUserCompanyAdministrator;
    //         }
    //     }
    // }

    // private setCurrentCommunityUserActor(): void {
    //     const isCurrentUserCommunityActor = this.sharedData$.value.currentCommunity.actors.find((communityActor) => communityActor.actor_uuid === this.sharedData$.value.currentUser?.uuid);
    //     if (isCurrentUserCommunityActor) {
    //         this.sharedData$.value.currentCommunityActor = isCurrentUserCommunityActor;
    //     }

    // }

    /**
     * Re emit shared data INTERNALLY
     * @param value 
    */
    private reEmitI(value?: any, source?: string): void {
        console.error('INTERN EMIT', source);
        this.sharedData$.value.isEmit = true;
        this.sharedData$.value.wasEmittedFrom = source ? source : 'reEmitI';
        if (value) {
            value.isEmit = true;
            value.wasEmmitedFrom = 'reEmitI';
        }
        const valToEmit = value ? value : this.sharedData$.value;
        this.sharedData$.next(JSON.parse(JSON.stringify(valToEmit)));
    }

    /**
     * Re emit shared data EXTERNALLY
     * @param value 
    */
    public reEmitE(value?: any, source?: string): void {
        console.error('EXTERN EMIT', source);

        this.sharedData$.value.isEmit = true;
        this.sharedData$.value.wasEmittedFrom = source ? source : 'reEmitE';
        if (value) {
            value.isEmit = true;
            value.wasEmmitedFrom = 'reEmitE';
        }
        const valToEmit = value ? value : this.sharedData$.value;
        this.sharedData$.next(JSON.parse(JSON.stringify(valToEmit)));

    }
}