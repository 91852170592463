import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-2',
  templateUrl: './step-2.component.html',
  styleUrls: ['./step-2.component.scss'],
})
export class Step2Component  implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  startSetup(): void {
    console.log('Starting profile setup');
    this.router.navigate(['config', 'first-account-configuration', 'step-3'])
    
  }
  
  goBack(): void {
    console.log('Going back');
    this.router.navigate(['config', 'first-account-configuration', 'step-1'])
  }

}
