import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LayoutsPageService {
  public selectedLayout$: BehaviorSubject<'EMPTY' | 'SIMPLE' | null> =
    new BehaviorSubject<'EMPTY' | 'SIMPLE' | null>(null);

  public isSection1Opened$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isSection2Opened$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isSection4Opened$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private router: Router) {

  }
}
