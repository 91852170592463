import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { CookieService } from '../../../core/cookies/services/cookie.service';
import { SharedAuthenticationService } from '../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class JWTInterceptor implements HttpInterceptor {

    constructor(private router: Router, private cookieService: CookieService, private authenticationService: SharedAuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        
        if (!request.url.includes('auth/')) {
            if (request.body instanceof FormData) {
                request = request.clone({

                    setHeaders: {
                        'Authorization': `Bearer ${this.cookieService.getCookie(environment.cookieName)}`,
                    },
                });
            } else {
                
                if(request.url.includes('static/')){
                    console.error('RTTTR');
                    
                }
                request = request.clone({

                    setHeaders: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${this.cookieService.getCookie(environment.cookieName)}`,
                    },
                });
            }
        }

        if (request.url.includes('reauth')) {
            return next.handle(request).pipe(
                tap((res: any) => {
                    
                    if (res && res.body && res.body.resolved) {
                        this.authenticationService.start_New_Session_By_Token(res.body.bearer);
                    }
                })
            )
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse): ObservableInput<any> => {
                if (error.status == 401) {
                    this.authenticationService.logout();
                    this.router.navigateByUrl('/auth/signin')
                }
                return throwError(error);
            }));
    }
}
