import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUsersService } from './services/shared-users.service';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [],
  imports: [CommonModule, FormsModule],
  providers: [SharedUsersService],
  exports: [],
})
export class SharedUsersModule {}
