import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutsPageComponent } from './pages/layouts-page/layouts-page.component';
import { LayoutsPageService } from './services/layouts-page.service';
import { LayoutSimpleComponent } from './components/layout-simple/layout-simple.component';
import { LayoutEmptyComponent } from './components/layout-empty/layout-empty.component';

import { SharedScreenModule } from '../_shared_/core/screen/shared-screen.module';
import { AppSharedModule } from '../_shared_/app-shared/app-shared.module';
import { IonRouterOutlet } from '@ionic/angular/standalone';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    LayoutsPageComponent,
    LayoutSimpleComponent,
    LayoutEmptyComponent,
  ],
  imports: [
    CommonModule,
    AppSharedModule,
    IonRouterOutlet,
    SharedScreenModule,
  ],
  providers: [LayoutsPageService],
  bootstrap: [],
  exports: [LayoutsPageComponent, LayoutSimpleComponent, LayoutEmptyComponent],
})
export class LayoutsModule {}


