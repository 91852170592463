import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasswordForgottenComponent } from './components/password-forgotten/password-forgotten.component';
import { AccountActivationComponent } from './pages/account-activation/account-activation.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';

const routes: Routes = [
  {
    path: 'signin',
    component: SigninComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'password-forgotten',
    component: PasswordForgottenComponent,
  },
  {
    path: 'account/activate/:activation_token',
    component: AccountActivationComponent,
  },
  {
    path: 'account/reset/:reset_token',
    component: ResetPasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
