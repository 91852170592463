import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/_shared_/app-shared/services/shared-data.service';

@Component({
  selector: 'app-step-4',
  templateUrl: './step-4.component.html',
  styleUrls: ['./step-4.component.scss'],
})
export class Step4Component implements OnInit {

  public user_uuid!: string;

  constructor(
    private router: Router,
    private sharedDataService: SharedDataService
  ) {
    this.sharedDataService.sharedData$.subscribe((sd) => {
      if (sd.currentUser && sd.currentUser.uuid) {
        this.user_uuid = sd.currentUser.uuid;
      }
    })
  }
  ngOnInit() { }

  startSetup(): void {
    console.log('Starting profile setup');
    this.router.navigate(['/', 'app', 'users', this.user_uuid, 'landing'])
  }

  goBack(): void {
    console.log('Going back');
    this.router.navigate(['config', 'first-account-configuration', 'step-3'])
  }

}
