import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";

@Injectable({ providedIn: 'root' })
export class SocketIoService {

    public ioSocket!: Socket;

    constructor(
        private socket: Socket
    ) {
        socket.on('connect', () => {
            this.ioSocket = socket;
        });

        return this;
    }

    sendMessage(action: string, value: any) {
        this.socket.emit(action, value);
    }

}
