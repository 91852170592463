import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FirstAccountConfigurationRoutingModule } from './first-account-configuration-routing.module';
import { FirstAccountConfigurationStateService } from './services/first-account-configurationState.service';
import { FirstAccountConfigurationDataService } from './services/first-account-configurationData.service';
import { SharedUsersModule } from '../_shared_/app/users/user-shared.module';
import { AppSharedModule } from '../_shared_/app-shared/app-shared.module';
import { Step0Component } from './pages/step-0/step-0.component';
import { Step1Component } from './pages/step-1/step-1.component';
import { Step2Component } from './pages/step-2/step-2.component';
import { Step3Component } from './pages/step-3/step-3.component';
import { Step4Component } from './pages/step-4/step-4.component';


@NgModule({
  declarations: [
    Step0Component,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component
  ],
  providers: [
    FirstAccountConfigurationStateService,
    FirstAccountConfigurationDataService
  ],
  imports: [
    CommonModule,
    FormsModule,
    FirstAccountConfigurationRoutingModule,
    SharedUsersModule,
    AppSharedModule
  ]
})
export class FirstAccountConfigurationModule { }
