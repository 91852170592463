<main class="education-setup">
  <header class="status-bar">
    <time>9:27</time>
  </header>

  <h1 class="setup-step">2/4: Education</h1>


  <form class="education-form">
    <div class="form-group">
      <label for="university" class="form-label">
        University<span class="required">*</span>
      </label>
      <div class="input-wrapper">
        <input id="university" type="text" value="University of Minho" class="form-input" />
      </div>
    </div>

    <div class="form-group">
      <label for="course" class="form-label">
        Course<span class="required">*</span>
      </label>
      <div class="input-wrapper">
        <input id="course" type="text" value="Engenharia informática" class="form-input" />
      </div>
    </div>

    <div class="form-group">
      <label for="year" class="form-label">
        Curricular year<span class="required">*</span>
      </label>
      <div class="input-wrapper">
        <input id="year" type="text" value="2nd" class="form-input" />
      </div>
    </div>

    <div class="form-group">
      <label for="mechNumber" class="form-label">
        Mechanographic number<span class="required">*</span>
      </label>
      <input id="mechNumber" type="text" placeholder="e.g. 908462355" class="form-input" />
    </div>
  </form>

  <nav class="navigation-buttons">
    <button class="back-button" (click)="goBack()">
      Back
    </button>
    <button class="next-button" (click)="startSetup()">Next</button>
  </nav>

  <div class="bottom-indicator"></div>
</main>