import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ScreenSize } from '../models/screen-size.model';

@Injectable()
export class SharedScreenService {
  public onScreenSize: BehaviorSubject<ScreenSize> = new BehaviorSubject(new ScreenSize(new DOMRect()));

  public observe(): SharedScreenService {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry) return;
        this.onScreenSize.next(new ScreenSize(entry.contentRect));
      });
    }).observe(document.body);

    return this;
  }
}
