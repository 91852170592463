import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { SigninComponent } from './pages/signin/signin.component';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SignupComponent } from './pages/signup/signup.component';
import { AppSharedModule } from '../_shared_/app-shared/app-shared.module';
import { AccountActivationComponent } from './pages/account-activation/account-activation.component';
import { PasswordForgottenComponent } from './components/password-forgotten/password-forgotten.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SharedAuthenticationModule } from '../_shared_/app/authentication/shared-authentication.module';

@NgModule({
    declarations: [
        SigninComponent,
        SignupComponent,
        AccountActivationComponent,
        PasswordForgottenComponent,
        ResetPasswordComponent,
    ], imports: [
        CommonModule,
        FormsModule,
        AppSharedModule,
        AuthenticationRoutingModule,
        SharedAuthenticationModule,
    ],
    providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class AuthenticationModule { }
