<div class="profile-setup-experience">
  <header class="status-bar">
    <time>9:27</time>
  </header>
  <main class="main-content">
    <p class="progress-indicator">3/4 Skills & Experience</p>
    <h1 class="section-title">Tell us about your experience</h1>
    <form class="experience-form">
      <div class="form-group">
        <label for="linkedin-profile" class="form-label">
          LinkedIn Profile<span class="required">*</span>
        </label>
        <input type="url" id="linkedin-profile" class="form-input" placeholder="e.g. https://www.linkedin.com/in/jose-silvax/" />
      </div>
      <div class="form-group">
        <label for="cv-upload" class="form-label">
          CV<span class="required">*</span>
        </label>
        <div class="file-upload">
          <span>Upload your CV</span>
        </div>
        <p class="file-types">Acceptable file types .pdf, .doc</p>
      </div>
      <div class="form-group">
        <label class="checkbox-label">
          <input type="checkbox" class="visually-hidden" />
          <span class="custom-checkbox"></span>
          I'm open to work opportunities
        </label>
      </div>
    </form>
  </main>
  <footer class="navigation-footer">
    <button class="back-button" (click)="goBack()">
      Back
    </button>
    <button class="next-button" (click)="startSetup()">Next</button>
  </footer>
  <div class="bottom-indicator" aria-hidden="true"></div>
</div>