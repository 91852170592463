import {
  IFirstAccountConfiguration,
  IFirstAccountConfigurationCreate,
  IFirstAccountConfigurationJoinAsCorporation,
  IFirstAccountConfigurationJoinAsPerson,
} from '../interfaces/first-account-configuration.interface';

export class MFirstAccountConfiguration implements IFirstAccountConfiguration {
  public create_or_join!: 'CREATE' | 'JOIN' | null;
  public create!: IFirstAccountConfigurationCreate | null;
  public join_as_company!: IFirstAccountConfigurationJoinAsCorporation | null;
  public join_as_person!: IFirstAccountConfigurationJoinAsPerson | null;

  constructor(config?: IFirstAccountConfiguration | null) {
    this.create_or_join = config?.create_or_join
      ? config.create_or_join
      : null;

    this.create = config?.create?.communityName ? config.create : null;

    this.join_as_company = config?.join_as_company
      ? config.join_as_company
      : null;

    this.join_as_person = config?.join_as_person?.community_uuid
      ? config.join_as_person
      : null;
  }
}
