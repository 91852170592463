import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JWTInterceptor } from './interceptors/jwt.interceptor';
import { SharedAuthenticationService } from './services/authentication.service';
import { CookieService } from '../../core/cookies/services/cookie.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    SharedAuthenticationService,
    CookieService,
    {provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true}
  ]
})
export class SharedAuthenticationModule { }
