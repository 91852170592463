import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { IUser } from 'src/app/_user/interfaces/user.interface';
import { SharedDataService } from 'src/app/_shared_/app-shared/services/shared-data.service';
import { AppLoaderService } from 'src/app/_shared_/core/loader/services/app-loader.service';
import { environment } from '../../../../../environments/environment';
import { CookieService } from '../../../core/cookies/services/cookie.service';
import { IUserSession } from '../interfaces/user-session.interface';
import { SocketIoService } from 'src/app/_shared_/app-shared/services/socket.io';

declare var FB: any;

@Injectable({
  providedIn: 'root',
})
export class SharedAuthenticationService {
  public user_session!: IUser;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private appLoaderService: AppLoaderService,
    private sharedDataService: SharedDataService,
    private sharedSocketIoService: SocketIoService,
  ) {
    this.checkCurrentSession();
  }

  /**
   * Get user session
   */
  public getCurrentSession(): IUser {
    return this.decodeJwt(this.cookieService.getCookie(environment.cookieName))?.user;
  }

  /**
   * Checks Current session and redirects if needed
   */
  private checkCurrentSession(): void {
    this.checkAppSession();
  }

  private checkAppSession(): void {
    this.user_session = this.decodeJwt(
      this.cookieService.getCookie(environment.cookieName)
    );
  }


  private checkFacebookSession(): void {
    (window as any).fbAsyncInit = () => {

      FB.AppEvents.logPageView();

      FB.getLoginStatus((response: any) => {

        if (response.status === 'connected') {
          this.router.navigate(['app', 'community']);

          // The user is logged in and has authenticated your
          // app, and response.authResponse supplies
          // the user's ID, a valid access token, a signed
          // request, and the time the access token
          // and signed request each expire.
          var uid = response.authResponse.userID;
          var accessToken = response.authResponse.accessToken;
        } else if (response.status === 'not_authorized') {
          // The user hasn't authorized your application.  They
          // must click the Login button, or you must call FB.login
          // in response to a user gesture, to launch a login dialog.
        } else {
          // The user isn't logged in to Facebook. You can launch a
          // login dialog with a user gesture, but the user may have
          // to log in to Facebook before authorizing your application.
        }
      }, this);
    };
  }

  public start_New_Session_By_Token(token: string): void {
    this.user_session = this.decodeJwt(token);
    this.sharedDataService.updateCurrentUser();
  }

  private decodeJwt(token?: string) {
    if (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    }
    return null;
  }

  public logout() {
    if (this.sharedSocketIoService.ioSocket) {
      if (this.getCurrentSession()) {
        this.sharedSocketIoService.sendMessage('A_USER_LOGGED_OUT', { user_uuid: this.getCurrentSession().uuid, socket_id: this.sharedSocketIoService.ioSocket.ioSocket.id });
      }
    }

    this.cookieService.deleteCookie(
      environment.cookieName,
      environment.cookiePath
    );

    this.appLoaderService.stopLoading();
  }

  signin(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request('POST', environment.apiUrl + 'auth/login', {
          body: { email, password },
          reportProgress: true,
          observe: 'response',
        })
        .subscribe({
          next: (res) => {

            const bearer = res.headers
              .get('Authorization')
              ?.replace('Bearer ', '');
            this.cookieService.setCookie(
              environment.cookieName,
              environment.cookiePath,
              bearer as string
            );
            this.user_session = res.body as IUser;

            this.sharedDataService.updateCurrentUser();

            this.sharedSocketIoService.sendMessage('GUEST_GOT_AUTHENTICATED', { user_uuid: this.user_session.uuid, socket_id: this.sharedSocketIoService.ioSocket.ioSocket.id });
            resolve(res.body);

          },
          error: (err) => {
            reject(err);
          }
        })
    });
  }

  public signup(credentials: {
    email: string;
    password: string;
    password_confirm: string;
  }): Promise<any> {
    return firstValueFrom(this.httpClient
      .post(environment.apiUrl + 'auth/signup', credentials));
  }

  public activateAccount(token: string): Promise<any> {
    return firstValueFrom(this.httpClient
      .post(environment.apiUrl + 'auth/account_activation', {
        activation_token: token,
      }));
  }

  public resendActivationMail(email: string): Promise<any> {
    return firstValueFrom(this.httpClient
      .post(environment.apiUrl + 'auth/account_activation_resend', {
        user_email: email,
      }));
  }

  public sendNewActivationMail(ancient_token: string): Promise<any> {
    return firstValueFrom(this.httpClient
      .post(environment.apiUrl + 'auth/account_activation_resend', {
        ancient_token,
      }));
  }

  public requestPasswordReset(email: string): Promise<any> {
    return firstValueFrom(this.httpClient
      .post(environment.apiUrl + 'auth/request_password_reset', { email }));
  }

  public passwordReset(data: {
    reset_token: string;
    password: string;
    password_confirm: string;
  }): Promise<any> {
    return firstValueFrom(this.httpClient
      .post(environment.apiUrl + 'auth/password_reset', data));
  }


  public postFirstAccountConfiguration(
    user_uuid: string,
    account_configuration: any
  ): Promise<any> {
    return firstValueFrom(this.httpClient
      .post(
        environment.apiUrl +
        'users/' +
        user_uuid +
        '/first_account_configuration',
        account_configuration
      ))
  }
}
