<div class="registration-container">
  <main>
    <div class="avatar-placeholder"></div>
    <h1 class="welcome-title">Welcome to X Project <span class="highlight">app!</span></h1>
    <p class="welcome-description">Create an account to get the most from event</p>

    <form (ngSubmit)="onSubmit()" class="registration-form">
      <div class="form-group">
        <label for="email" class="form-label">Email</label>
        <input type="email" id="email" name="email" [(ngModel)]="email" required class="form-input" autocomplete="false" />
      </div>

      <div class="form-group">
        <label for="password" class="form-label">Password</label>
        <input type="password" id="password" name="password" [(ngModel)]="password" required class="form-input" autocomplete="false" />
        <p class="password-hint">Use at least 8 characters</p>
      </div>

      <div class="form-group">
        <label for="confirmPassword" class="form-label">Confirm password</label>
        <input type="password" id="confirmPassword" name="confirmPassword" [(ngModel)]="confirmPassword" required class="form-input" autocomplete="false" />
      </div>

      <div class="terms-agreement">
        <input type="checkbox" id="agreeTerms" name="agreeTerms" [(ngModel)]="cgu_cgv" required autocomplete="false" />
        <label for="agreeTerms" class="terms-label">
          I agree to <a href="#" class="terms-link">Terms of use</a> of this service
        </label>
      </div>

      <button type="submit" class="submit-button">Create account</button>
    </form>

    <p class="login-link">
      Already have an account? <a href="#" class="login-anchor">Log in here</a>
    </p>
  </main>

  <footer class="bottom-indicator"></footer>
</div>