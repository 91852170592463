import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { SharedData } from 'src/app/_shared_/app-shared/models/shared-data.model';
import { SharedDataService } from 'src/app/_shared_/app-shared/services/shared-data.service';
import { ActorRoles } from 'src/app/_shared_/app/users/models/actor-roles.enum';
import { OwnerRoles } from 'src/app/_shared_/app/users/models/owner-roles.enum';
import { SharedScreenService } from 'src/app/_shared_/core/screen/services/shared-screen.service';
import { LayoutsPageService } from '../../services/layouts-page.service';
import { Router } from '@angular/router';

@Component({
  selector: 'layout-simple',
  templateUrl: './layout-simple.component.html',
  styleUrls: ['./layout-simple.component.scss'],
})
export class LayoutSimpleComponent implements AfterViewInit, OnDestroy {
  public companyOrCommunitySwitch: 'COMMUNITIES' | 'COMPANIES' | null = null;

  public OwnerRoles = OwnerRoles;
  public ActorRoles = ActorRoles;
  public sharedData!: SharedData;
  private sharedDataUnsub$: Subject<void> = new Subject();


  constructor(
    private router: Router,
    private sharedDataService: SharedDataService,
    private layoutsPageService: LayoutsPageService,
    private sharedScreenService: SharedScreenService,
  ) {
    console.log('ZEFZEFZEF');
    
    this.sharedDataService.watchOnline$().pipe(takeUntil(this.sharedDataUnsub$)).subscribe((sharedData) => {
      console.log('Shared Data');
      this.sharedData = sharedData;
      this.initAutoScreenResize();
    })
  }


  ngAfterViewInit() {
    this.initAutoScreenResize();
  }

  ngOnDestroy(): void {
    this.sharedDataUnsub$.next();
    this.sharedDataUnsub$.complete();
  }

  public goToHome(): void{
    this.router.navigate(['/'])
  }

  private async initAutoScreenResize(): Promise<void> {
    this.sharedScreenService
      .observe()
      .onScreenSize.subscribe((screenSize) => {


        if (screenSize.type === 'LARGE') {
          this.toggleSection1(true);
          // this.toggleSection2(true);
          this.addMargionToSection3(true, true);
          this.toggleSection4(true);
        }
        if (screenSize.type === 'SMALL') {
          this.toggleSection1(false);
          this.addMargionToSection3(false, true);
          // this.toggleSection2(true);
          this.toggleSection4(true);
        }
        if (screenSize.type === 'TABLET') {
          this.toggleSection1(false);
          // this.toggleSection2(true);
          this.addMargionToSection3(false, false);
          this.toggleSection4(false);
        }
        if (screenSize.type === 'MOBILE') {
          this.toggleSection1(false);
          // this.toggleSection2(false);
          this.addMargionToSection3(false, false);
          this.toggleSection4(false);
        }
      });

    this.layoutsPageService.isSection1Opened$.pipe(distinctUntilChanged()).subscribe((value: boolean) => {
      this.toggleSection1(value);
      this.addMargionToSection3(value, true);
    });

    // this.layoutsPageService.isSection2Opened$.subscribe((value: boolean) => {
    //   this.toggleSection2(value);
    // });

    this.layoutsPageService.isSection4Opened$.subscribe((value: boolean) => {
      this.toggleSection4(value);
    });

  }


  public toggleSection1(value: boolean, progatate?: boolean): void {
    const element: HTMLElement = document.getElementsByClassName(
      'section-1'
    )[0] as HTMLElement;

    if (value && element) {
      element?.classList.add('opened');
      element?.classList.remove('closed');
      this.layoutsPageService.isSection1Opened$.next(true);
    } else {
      element?.classList.add('closed');
      element?.classList.remove('opened');
      this.layoutsPageService.isSection1Opened$.next(false);
    }
  }

  // public toggleSection2(value: boolean): void {
  //   const element: HTMLElement = document.getElementsByClassName(
  //     'section-2'
  //   )[0] as HTMLElement;

  //   if (value) {
  //     element?.classList.add('opened');
  //     element?.classList.remove('closed');
  //   } else {
  //     element?.classList.add('closed');
  //     element?.classList.remove('opened');
  //   }
  // }


  public toggleSection4(value: boolean): void {
    const element: HTMLElement = document.getElementsByClassName(
      'section-4'
    )[0] as HTMLElement;

    if (value) {
      element?.classList.add('opened');
      element?.classList.remove('closed');
    } else {
      element?.classList.add('closed');
      element?.classList.remove('opened');
    }
  }

  public addMargionToSection3(marginLeft: boolean, marginRight: boolean): void {
    const element: HTMLElement = document.getElementsByClassName(
      'section-3'
    )[0] as HTMLElement;
    if (!element) return;
    if (marginLeft) {
      element.style.marginLeft = '296px';
    } else {
      element.style.marginLeft = '0px';
    }
    if (marginRight) {
      element.style.marginRight = '20px';
    } else {
      element.style.marginRight = '0px';
    }

  }

  public changeCommunity(): void {
    this.companyOrCommunitySwitch = 'COMMUNITIES';
  }

  public onClose(): void {
    this.companyOrCommunitySwitch = null;
  }
}
